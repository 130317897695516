body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: space-between;
          align-content: space-between;
  height: 100vh;
  font-family: 'Bitter', serif;
}

h1{
  font-size: 1rem;
  line-height: 1.5rem;
  color: #312783;
}

h2{
  font-size: 1rem;
  line-height: 1.5rem;
  color: #312783;
  margin-bottom: .75rem;
}

header, footer{
  width: calc(100% - 2rem);
  padding: 1rem;
}

.layout--container, .parameters{
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-self: stretch;
          align-self: stretch;
}

.parameters{
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

form{
  text-align: left;
}

.parameters{
  padding: 1rem;
}

.layout--container{
  /*background-color: #ddd;*/
  padding: 1rem;
}

.pagelayout{
  width: 304px;
  height: 500px;
}

.pagelayoutcontainer{
  display: none;
}
#layout{
  /*width: 100%;
  height: auto;*/
}

canvas{
  border: 1px solid black;
}

@media print{
  header, footer, .parameters{
    display: none;
  }
  .layout--container{
    padding: 0;
  }
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

label{
  color: #312783;
  font-size: .875rem;
  line-height: 1.5rem;
  margin-bottom: .75rem;
  min-width: 10rem;
  display: inline-block;
  vertical-align: middle;
}

input[type=text], input[type=number]{
  font-family: 'Bitter', serif;
  font-size: .8375rem;
  line-height: .8375rem;
  padding: .5rem;
  border: 1px solid #312783;
  color: #312783;
  margin-bottom: .75rem;
  min-width: 14rem;
  display: inline-block;
  vertical-align: middle;
}

input[type=file], input[type=checkbox], select{
  display: inline-block;
  vertical-align: middle;
  margin-bottom: .75rem;
  color: #312783;
}

select{
  font-family: 'Bitter', serif;
  font-size: .8375rem;
  line-height: .8375rem;
  padding: 1rem;
  border: 1px solid #312783;
  color: #312783;
  margin-bottom: .75rem;
  min-width: 14rem;
}

button, input[type=submit], .button{
  font-family: 'Bitter', serif;
  font-size: 1rem;
  line-height: 1rem;
  padding: .5rem 1rem;
  border: 2px solid #312783;
  background-color: #312783;
  color: #fff;
  margin: .5rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.download--link--button{
  display: none;
}

.download--link{
  display: inline-block;
}

button:hover, button:active, input[type=submit]:hover, input[type=submit]:active{
  color: #312783;
  background-color: #fff;
}

input[type=submit], .download--link{
  background-color: #e8412c;
  border-color: #e8412c;
  text-decoration: none;
}

input[type=submit]:hover, input[type=submit]:active, .download--link:hover, .download--link:active{
  color: #e8412c;
  background-color: #fff;
}

.dpi--ok{
  color: #0a0;
}

.dpi--not-ok{
  color: #e8412c;
}

p{
  text-align: left;
  margin-top: 0;
}

.loading-screen{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .85);
  display: none;
}

.loading-screen p{
  color: #fff;
  font-size: 2rem;
  line-height: 2rem;
  margin: 3rem;
  text-align: center;
  font-family: 'Bitter', serif;
}

.loading-screen--visible .loading-screen{
  display: none;
}
